import React, { Component } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import loadable from "@loadable/component";

import "../Register.css";

const Header = loadable(() => import("../Header"));
const Footer = loadable(() => import("../../footer/Footer"));

class FifteenthPage extends Component {
  constructor() {
    super();
    this.state = { buttonClicked: false };
  }

  componentDidMount() {
    document.getElementById("scroll-to-this").scrollIntoView();
  }

  render() {
    const backgroundStyle = {
      // backgroundImage: "url(/images/greycoachregimage.png)",
      // backgroundRepeat: "no-repeat",
      // backgroundSize: "auto",
      background: "rgb(235, 235, 235)",
      minHeight: "91%",
      // height: "800px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };

    return (
      <div
        className="welcome-coach"
        style={{ overflow: "scroll", height: "100vh" }}
      >
        <Header />
        <div style={backgroundStyle} className="big-container-reg">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                padding: "20px",
                width: "50%",
                border: "5px solid rgb(43, 43, 44)",
                borderRadius: "20px",
                marginBottom: "30px",
                fontSize: "35px",
                fontWeight: 600,
              }}
            >
              PPA App
            </div>
            <div
              id="scroll-to-this"
              className="register-welcome-container part1-policies-container register-part1-policies-container-shadow"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    color: "#0d7ba0",
                    fontSize: "25px",
                    fontWeight: 600,
                    paddingBottom: "20px",
                  }}
                >
                  The Portal includes a web based application, and a phone based
                  application. This means you can access your Portal Account
                  from your computer and your phone.
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div>
                    <img
                      src="/images/clickedphone.png"
                      alt="phone"
                      style={{ width: "100px" }}
                    />
                    <img
                      src="/images/laptop-reg-flow.png"
                      alt="laptop"
                      style={{ width: "100px" }}
                    />
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  {this.state.buttonClicked ? (
                    <img
                      src="/images/greencheckmark.png"
                      alt="green checkmark"
                    />
                  ) : (
                    <img
                      style={{ cursor: "pointer" }}
                      src="/images/graycheckmark.png"
                      alt="green checkmark"
                      onClick={() => this.setState({ buttonClicked: true })}
                    />
                  )}
                  {this.state.buttonClicked ? (
                    <button
                      id="register-coach-button"
                      className="register-coach-button"
                    >
                      <Link
                        id="register-coach-hyperlink"
                        style={{
                          textDecoration: "none",
                          color: "black",
                        }}
                        to={`/register/coach/new-seventeenth-page`}
                      >
                        Continue
                      </Link>
                    </button>
                  ) : null}
                </div>
              </div>
              {/* <div>
                <img src="/images/clickedphone.png" alt="phone" />
              </div> */}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default FifteenthPage;
