import React, { Component } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import loadable from "@loadable/component";

import "../Register.css";

const Header = loadable(() => import("../Header"));
const Footer = loadable(() => import("../../footer/Footer"));

class EighthPage extends Component {
  componentDidMount() {
    document.getElementById("scroll-to-this").scrollIntoView();
  }

  render() {
    const backgroundStyle = {
      // backgroundImage: "url(/images/greycoachregimage.png)",
      // backgroundRepeat: "no-repeat",
      // backgroundSize: "auto",
      background: "rgb(235, 235, 235)",
      minHeight: "91%",
      // height: "800px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };

    return (
      <div
        className="welcome-coach"
        style={{ height: "100vh", overflow: "scroll" }}
      >
        <Header />
        <div style={backgroundStyle} className="big-container-reg">
          <div
            id="scroll-to-this"
            className="register-welcome-container nobg-container"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                width: "100%",
              }}
            >
              <div
                style={{
                  color: "#0a85ad",
                  marginBottom: "60px",
                  display: "flex",
                  flexDirection: "column",
                }}
                className="notice-container register-notice-container-shadow-yellow"
              >
                <div style={{ fontSize: "20px", fontWeight: 600 }}>
                  <b>Player Communication Guidelines</b>
                </div>
                <div style={{ fontSize: "15px" }}>
                  <b>
                    When communicating with players don't forget these rules:
                  </b>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="register-round-containing-container">
                  <div className="register-round-container register-small-notice-container-shadow-yellow">
                    <b>1</b>
                  </div>
                  <div className="register-next-to-round-container register-small-notice-container-shadow-yellow notice-container">
                    Don't Curse.
                  </div>
                </div>
                <div className="register-round-containing-container">
                  <div className="register-round-container register-small-notice-container-shadow-yellow">
                    <b>2</b>
                  </div>
                  <div className="register-next-to-round-container register-small-notice-container-shadow-yellow notice-container">
                    Be professional - don't message players about their social
                    lives.
                  </div>
                </div>
                <div className="register-round-containing-container">
                  <div className="register-round-container register-small-notice-container-shadow-yellow">
                    <b>3</b>
                  </div>
                  <div className="register-next-to-round-container register-small-notice-container-shadow-yellow notice-container">
                    When your coaching: be loud, vocal, encouraging, and
                    positive.
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  id="register-coach-button"
                  className="register-coach-button"
                >
                  <Link
                    id="register-coach-hyperlink"
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                    to={`/register/coach/new-ninth-page`}
                  >
                    <b>Continue</b>
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default EighthPage;
