import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import config from '../config';
import axios from "axios";
import * as JWT from 'jwt-decode';

export default function withAuth(ProtectedComponent, pageName = "") {
	return class extends Component {
		constructor() {
			super();
			this.state = {
				loading: true,
				redirect: false,
				token: ""
			};
		}

		componentDidMount() {
			const token = localStorage.getItem("token");
			if(token){
				let userInfo = JWT(token);
				const { userId, permissions} = userInfo;
				if(pageName){
					axios.post(
						config.apiUrl + `analytics/portalActivity`,
						{userId, userType: permissions , pageType: pageName},
						{
							headers: {
								"x-access-token": token
							}
						}
					).then((result) => {
						console.log(result);
					}).catch((err) => {
						console.log(err);
					});
				}
				
			}
			this.setState({ token });
			token
				? this.setState({ loading: false })
				: this.setState({ loading: false, redirect: true });
		}

		render() {
			const { loading, redirect, token } = this.state;
			if (loading) {
				return null;
			}
			if (redirect) {
				return <Redirect to="/login/user" />;
			}
			return <ProtectedComponent {...this.props} auth={token} />;
		}
	};
}
