import React, { Component } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import loadable from "@loadable/component";

import "../Register.css";

const Header = loadable(() => import("../Header"));
const Footer = loadable(() => import("../../footer/Footer"));

class TwelfthPage extends Component {
  constructor() {
    super();
    this.state = { buttonClicked: false };
  }

  componentDidMount() {
    document.getElementById("scroll-to-this").scrollIntoView();
  }

  render() {
    const backgroundStyle = {
      // backgroundImage: "url(/images/greycoachregimage.png)",
      // backgroundRepeat: "no-repeat",
      // backgroundSize: "auto",
      background: "rgb(235, 235, 235)",
      minHeight: "91%",
      // height: "800px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };

    return (
      <div
        className="welcome-coach"
        style={{ overflow: "scroll", height: "100vh" }}
      >
        <Header />
        <div style={backgroundStyle} className="big-container-reg">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                padding: "15px",
                width: "50%",
                border: "5px solid rgb(43, 43, 44)",
                borderRadius: "20px",
                marginBottom: "30px",
                fontSize: "25px",
                fontWeight: 600,
              }}
            >
              Confidentiality Agreement
            </div>
            <div
              id="scroll-to-this"
              className="register-welcome-container part1-policies-container register-part1-policies-container-shadow"
              style={{ width: "100%" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    color: "#0d7ba0",
                    fontSize: "15px",
                    paddingBottom: "20px",
                  }}
                >
                  <div
                    style={{
                      fontWeight: 600,
                      marginBottom: "10px",
                    }}
                  >
                    You cannot ever use PPA contact lists for any reason except
                    on behalf of PPA functions. This means:
                  </div>
                  <div>
                    - You cannot send mass emails (or other forms of
                    communications) to parents that don’t relate to PPA
                  </div>
                  <div>
                    - You cannot share or sell PPA contact lists with anyone
                    else
                  </div>
                  <div
                    style={{
                      fontWeight: 600,
                      marginBottom: "10px",
                      marginTop: "10px",
                    }}
                  >
                    You cannot ever share any internal documents with anyone
                    outside of PPA. This includes:
                  </div>
                  <div>- Our contact lists</div>
                  <div>
                    - Any PPA materials which relate to our business operations
                  </div>
                  <div>
                    - Any internal communications like emails, slack, or texts
                    that staff members share with each other.
                  </div>
                  <div
                    style={{
                      fontWeight: 600,
                      marginBottom: "10px",
                      marginTop: "10px",
                    }}
                  >
                    When are you done working with PPA, you cease any right to
                    access our internal materials: This includes
                  </div>
                  <div>- Our contact lists</div>
                  <div>
                    - Any PPA materials which relate to our business operations
                  </div>
                  <div>
                    - Any internal communications like emails, slack, or texts
                    that staff shares with each other
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {this.state.buttonClicked ? (
                    <img
                      src="/images/greencheckmark.png"
                      alt="green checkmark"
                    />
                  ) : (
                    <img
                      style={{ cursor: "pointer" }}
                      src="/images/graycheckmark.png"
                      alt="green checkmark"
                      onClick={() => this.setState({ buttonClicked: true })}
                    />
                  )}
                  {this.state.buttonClicked ? (
                    <button
                      id="register-coach-button"
                      className="register-coach-button"
                    >
                      <Link
                        id="register-coach-hyperlink"
                        style={{
                          textDecoration: "none",
                          color: "black",
                        }}
                        to={`/register/coach/new-thirteenth-page`}
                      >
                        Continue
                      </Link>
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default TwelfthPage;
