import React, { Component } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import loadable from "@loadable/component";

import "../Register.css";

const Header = loadable(() => import("../Header"));
const Footer = loadable(() => import("../../footer/Footer"));

class TenthPage extends Component {
  constructor() {
    super();
    this.state = { buttonClicked: false };
  }

  componentDidMount() {
    document.getElementById("scroll-to-this").scrollIntoView();
  }

  render() {
    const backgroundStyle = {
      // backgroundImage: "url(/images/greycoachregimage.png)",
      // backgroundRepeat: "no-repeat",
      // backgroundSize: "auto",
      background: "rgb(235, 235, 235)",
      minHeight: "91%",
      // height: "800px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };

    return (
      <div
        className="welcome-coach"
        style={{ height: "100vh", overflow: "scroll" }}
      >
        <Header />
        <div style={backgroundStyle} className="big-container-reg">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                padding: "15px",
                width: "50%",
                border: "5px solid rgb(43, 43, 44)",
                borderRadius: "20px",
                marginBottom: "30px",
                fontSize: "25px",
                fontWeight: 600,
              }}
            >
              Working with Kids
            </div>
            <div
              id="scroll-to-this"
              className="register-welcome-container part1-policies-container register-part1-policies-container-shadow"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    color: "#0d7ba0",
                    fontSize: "15px",
                    paddingBottom: "20px",
                  }}
                >
                  <div style={{ fontWeight: 600 }}>
                    Working with and around children can be very sensitive and
                    you must be careful. Kids themselves are sensitive, and
                    parents are also sensitive to how people act around their
                    kids. You must be very careful to avoid putting yourself in
                    a bad position. Our policies to keep you safe:
                  </div>
                  <div>
                    - Limit any physical contact with kids beyond high fives or
                    huddles.
                  </div>
                  <div>
                    - If a player is hurt, says their hurt, or even remotely
                    looks unfit to play, always apply the utmost prudence by
                    taking them out of an activity. If there is a serious
                    injury, act swiftly to help the player, inform the parents.
                  </div>
                  <div>
                    - Do not connect with any players on social media platforms,
                    do not communicate with players off the field about personal
                    matters.
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {this.state.buttonClicked ? (
                    <img
                      src="/images/greencheckmark.png"
                      alt="green checkmark"
                    />
                  ) : (
                    <img
                      style={{ cursor: "pointer" }}
                      src="/images/graycheckmark.png"
                      alt="green checkmark"
                      onClick={() => this.setState({ buttonClicked: true })}
                    />
                  )}
                  {this.state.buttonClicked ? (
                    <button
                      id="register-coach-button"
                      className="register-coach-button"
                    >
                      <Link
                        id="register-coach-hyperlink"
                        style={{
                          textDecoration: "none",
                          color: "black",
                        }}
                        to={`/register/coach/new-eleventh-page`}
                      >
                        Continue
                      </Link>
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default TenthPage;
