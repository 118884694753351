import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import loadable from "@loadable/component";

import App from "../App";
import withAuth from "../helpers/withAuth";
import SecondPage from "../components/register-coach/coachNewOnboarding/secondPage";
import ThirdPage from "../components/register-coach/coachNewOnboarding/thirdPage";
import FourthPage from "../components/register-coach/coachNewOnboarding/fourthPage";
import FifthPage from "../components/register-coach/coachNewOnboarding/fifthPage";
import SixthPage from "../components/register-coach/coachNewOnboarding/sixthPage";
import SeventhPage from "../components/register-coach/coachNewOnboarding/seventhPage";
import EighthPage from "../components/register-coach/coachNewOnboarding/eighthPage";
import NinthPage from "../components/register-coach/coachNewOnboarding/ninthPage";
import TenthPage from "../components/register-coach/coachNewOnboarding/tenthPage";
import EleventhPage from "../components/register-coach/coachNewOnboarding/eleventhPage";
import TwelfthPage from "../components/register-coach/coachNewOnboarding/twelfthPage";
import ThirteenthPage from "../components/register-coach/coachNewOnboarding/thirteenthPage";
import FourteenthPage from "../components/register-coach/coachNewOnboarding/fourteenthPage";
import FifteenthPage from "../components/register-coach/coachNewOnboarding/fifteenthPage";
import SixteenthPage from "../components/register-coach/coachNewOnboarding/sixteenthPage";
import SeventeenthPage from "../components/register-coach/coachNewOnboarding/seventeenthPage";

// lazy load of components
const Login = loadable(() => import("../components/login/Login"));
const RegisterUser = loadable(() =>
  import("../components/register-user/Register")
);
const RegisterCoach = loadable(() =>
  import("../components/register-coach/Register")
);
const CoachSteps = loadable(() =>
  import("../components/register-coach/CoachSteps")
);
const WelcomeCoach = loadable(() =>
  import("../components/register-coach/Welcome")
);

const Dashboard = loadable(() => import("../components/dashboard/Dashboard"));
const UserProgramsOptions = loadable(() =>
  import("../components/dashboard/UserProgramsOptions")
);

const UserRegistrationSelectPlayer = loadable(() =>
  import("../components/dashboard/UserRegistrationSelectPlayer")
);
const UserRegistrationSelectProgram = loadable(() =>
  import("../components/dashboard/UserRegistrationSelectProgram")
);
const UserRegistrationSelectProgramType = loadable(() =>
  import("../components/dashboard/UserRegistrationSelectProgramType")
);
const UserRegistrationSelectProgramSubType = loadable(() =>
  import("../components/dashboard/UserRegistrationSelectProgramSubType")
);
const UserAllProgram = loadable(() =>
  import("../components/dashboard/UserAllProgram")
);
const PrivateTraining = loadable(() =>
  import("../components/dashboard/PrivateTraining")
);
const premierProgram = loadable(() =>
  import("../components/dashboard/premierProgram")
);
const DevelopmentProgram = loadable(() =>
  import("../components/dashboard/DevelopmentProgram")
);
const UserRegistrationCheckout = loadable(() =>
  import("../components/dashboard/UserRegistrationCheckout")
);
const UserRegistrationCongratulations = loadable(() =>
  import("../components/dashboard/UserRegistrationCongratulations")
);
const UserRegistrationQuestions = loadable(() =>
  import("../components/dashboard/UserRegistrationQuestions")
);
const CampWaiver = loadable(() => import("../components/dashboard/CampWaiver"));
const ParentRequest = loadable(() =>
  import("../components/dashboard/ParentRequest")
);
const ContactInfo = loadable(() =>
  import("../components/dashboard/ContactInfo")
);
const ParentRequestConfirmation = loadable(() =>
  import("../components/dashboard/ParentRequestConfirmation")
);
const ParentInformation = loadable(() =>
  import("../components/frontfacing-registration/parentInformation")
);
const FrontFacingInviteFriends = loadable(() =>
  import("../components/frontfacing-registration/inviteFriends")
);
const CreateTournamentTeam = loadable(() =>
  import("../components/tournaments/CreateTeam")
);
const TournamentInvitation = loadable(() =>
  import("../components/tournaments/Invitation")
);
const TournamentTeamConfirmation = loadable(() =>
  import("../components/tournaments/Confirmation")
);
const TournamentFreeAgentConfirmation = loadable(() =>
  import("../components/tournaments/FreeAgentConfirmation")
);
const TeampoolTeamConfirmation = loadable(() =>
  import("../components/dashboard/TeampoolTeamConfirmation")
);
const TeampoolConfirmation = loadable(() =>
  import("../components/dashboard/TeampoolRegistrationConfirmation")
);
const TeamWithCodeConfirmation = loadable(() =>
  import("../components/dashboard/TeamWithCodeConfirmation")
);
const TryoutWithCodeConfirmation = loadable(() =>
  import("../components/dashboard/TryoutWithCodeConfirmation")
);

const CoachReviewRating = loadable(() =>
  import("../components/coachreview/Rating")
);
const CoachReviewComment = loadable(() =>
  import("../components/coachreview/Comment")
);
const CoachReviewCompliment = loadable(() =>
  import("../components/coachreview/Compliment")
);
const CoachReviewConfirmation = loadable(() =>
  import("../components/coachreview/Confirmation")
);
const CoachReviewGift = loadable(() =>
  import("../components/coachreview/Gift")
);
const CoachReviewRatingProfile = loadable(() =>
  import("../components/coachreview/CoachReviewRatingProfile")
);
const CoachReviewList = loadable(() =>
  import("../components/coachreview/CoachReviewList")
);
const CoachResources = loadable(() =>
  import("../components/dashboard/CoachResources")
);
const CoachCoverageRequest = loadable(() =>
  import("../components/account-user/CoachCoverageRequest")
);
const UserMessages = loadable(() =>
  import("../components/dashboard/UserMessages")
);
const UserSchedules = loadable(() =>
  import("../components/dashboard/UserSchedules")
);
const ContactUs = loadable(() =>
  import("../components/dashboard/ContactUs")
);
const UserUpdateProfile = loadable(() =>
  import("../components/account-user/UpdateProfile")
);
const UserMyPlayers = loadable(() =>
  import("../components/account-user/MyPlayers")
);
const UserAddPlayer = loadable(() =>
  import("../components/account-user/UserAddPlayer")
);
const UserEditPlayer = loadable(() =>
  import("../components/account-user/UserEditPlayer")
);
const UserChangePassword = loadable(() =>
  import("../components/account-user/UserChangePassword")
);
const UserPayments = loadable(() =>
  import("../components/account-user/UserPayments")
);
const ForgotPassword = loadable(() =>
  import("../components/account-user/ForgotPassword")
);
const DeleteUser = loadable(() =>
  import("../components/account-user/DeleteUser")
);
const League = loadable(() =>
  import("../components/dashboard/program-options/League")
);
const CoachCoverage = loadable(() =>
  import("../components/account-user/CoachCoverage")
);
const LeagueArchive = loadable(() =>
  import("../components/dashboard/LeagueArchive")
);

const Page404 = loadable(() => import("../components/page-404/Page-404"));
export default (
  <App>
    <Switch>
      {/* Default Router: Will be redirected to User Dashboard (User Programs) by default*/}
      <Route path="/" exact render={() => <Redirect to="/programs" />} />
      {/* New coach onboarding system */}
      <Route path="/register/coach/new-second-page" component={SecondPage} />
      <Route path="/register/coach/new-third-page" component={ThirdPage} />
      <Route path="/register/coach/new-fourth-page" component={FourthPage} />
      <Route path="/register/coach/new-fifth-page" component={FifthPage} />
      <Route path="/register/coach/new-sixth-page" component={SixthPage} />
      <Route path="/register/coach/new-seventh-page" component={SeventhPage} />
      <Route path="/register/coach/new-eighth-page" component={EighthPage} />
      <Route path="/register/coach/new-ninth-page" component={NinthPage} />
      <Route path="/register/coach/new-tenth-page" component={TenthPage} />
      <Route
        path="/register/coach/new-eleventh-page"
        component={EleventhPage}
      />
      <Route path="/register/coach/new-twelfth-page" component={TwelfthPage} />
      <Route
        path="/register/coach/new-thirteenth-page"
        component={ThirteenthPage}
      />
      <Route
        path="/register/coach/new-fourteenth-page"
        component={FourteenthPage}
      />
      <Route
        path="/register/coach/new-fifteenth-page"
        component={FifteenthPage}
      />
      <Route
        path="/register/coach/new-sixteenth-page"
        component={SixteenthPage}
      />
      <Route
        path="/register/coach/new-seventeenth-page"
        component={SeventeenthPage}
      />

      {/* Parent and Coach Login Pages */}
      <Route
        path="/login/user"
        render={(props) => <Login userType="user" {...props} />}
      />
      <Route
        path="/login/coach"
        render={(props) => <Login userType="coach" {...props} />}
      />
      {/* Parent and Coach Register Pages */}
      <Route path="/register/user" component={RegisterUser} />
      <Route path="/register/coach/join" component={RegisterCoach} />
      <Route
        path="/register/coach/join-slack"
        render={(props) => <CoachSteps step="Joinslack" {...props} />}
      />
      <Route
        path="/register/coach/background-check"
        render={(props) => <CoachSteps step="BackgroundCheck" {...props} />}
      />
      <Route
        path="/register/coach/send-photo"
        render={(props) => <CoachSteps step="SendPhoto" {...props} />}
      />
      <Route
        path="/register/coach/review-policy"
        exact
        render={(props) => <CoachSteps step="ReviewPolicy" {...props} />}
      />
      <Route
        path="/register/coach/review-policy/company-mission"
        render={(props) => (
          <CoachSteps step="ReviewPolicyCompanyMission" {...props} />
        )}
      />
      <Route
        path="/register/coach/review-policy/non-compete-info"
        render={(props) => (
          <CoachSteps step="ReviewPolicyNonCompeteInfo" {...props} />
        )}
      />
      <Route
        path="/register/coach/review-policy/confidential-info"
        render={(props) => (
          <CoachSteps step="ReviewPolicyConfidentialInfo" {...props} />
        )}
      />
      <Route
        path="/register/coach/review-policy/vacation-policy"
        render={(props) => (
          <CoachSteps step="ReviewPolicyVacationPolicy" {...props} />
        )}
      />
      <Route
        path="/register/coach/review-policy/attendance-coverage-policy"
        render={(props) => (
          <CoachSteps step="ReviewPolicyAttendanceCoveragePolicy" {...props} />
        )}
      />
      <Route
        path="/register/coach/review-policy/attendance-coverage-policy2"
        render={(props) => (
          <CoachSteps step="ReviewPolicyAttendanceCoveragePolicy2" {...props} />
        )}
      />
      <Route
        path="/register/coach/review-policy/professionalism"
        render={(props) => (
          <CoachSteps step="ReviewPolicyProfessionalism" {...props} />
        )}
      />
      <Route
        path="/register/coach/review-policy/communication"
        render={(props) => (
          <CoachSteps step="ReviewPolicyCommunication" {...props} />
        )}
      />
      <Route
        path="/register/coach/review-policy/dress-code"
        render={(props) => (
          <CoachSteps step="ReviewPolicyDressCode" {...props} />
        )}
      />
      <Route
        path="/register/coach/review-policy/congrats"
        render={(props) => (
          <CoachSteps step="ReviewPolicyCongrats" {...props} />
        )}
      />
      <Route
        path="/register/coach/review-contract"
        exact
        render={(props) => <CoachSteps step="ReviewContract" {...props} />}
      />
      <Route
        path="/register/coach/welcome"
        exact
        render={(props) => <CoachSteps step="Welcome" {...props} />}
      />
      {/* Coach Welcome Page */}
      <Route path="/welcome/coach" component={WelcomeCoach} />
      {/* User Dashboard: it is User Programs */}
      <Route
        exact
        path="/programs"
        component={withAuth(Dashboard, "dashboard")}
      />
      <Route
        path="/programs/profile/:type/:id"
        component={withAuth(UserProgramsOptions)}
      />
      {/* User Registration */}
      <Route
        exact
        path="/user/registration"
        render={() => <Redirect to="/user/registration/selectprogramtype" />}
      />
      <Route
        exact
        path="/user/registration/selectplayer"
        component={withAuth(UserRegistrationSelectPlayer)}
      />
      <Route
        path="/user/registration/selectprogram/all"
        render={() => <Redirect to="/user/registration/allprogram" />}
      />
      <Route
        exact
        path="/user/registration/selectprogramtype"
        component={withAuth(UserRegistrationSelectProgramType, "registration")}
      />
      <Route
        exact
        path="/user/registration/selectprogramsubtype/:type"
        component={withAuth(UserRegistrationSelectProgramSubType)}
      />
      <Route
        path="/user/registration/selectprogram/private-training"
        render={() => <Redirect to="/user/registration/private-training" />}
      />
      <Route
        path="/user/registration/selectplayer/:type/:referralId"
        component={withAuth(UserRegistrationSelectPlayer)}
      />
      <Route
        path="/user/registration/selectplayer/:type"
        component={withAuth(UserRegistrationSelectPlayer)}
      />
      <Route
        exact
        path="/user/registration/selectprogram"
        component={UserRegistrationSelectProgram}
      />
      <Route
        exact
        path="/user/registration/selectprogram/:type/:referralId"
        component={UserRegistrationSelectProgram}
      />
      <Route
        exact
        path="/user/registration/selectprogram/:type"
        component={UserRegistrationSelectProgram}
      />
      <Route
        exact
        path="/user/registration/allprogram"
        component={withAuth(UserAllProgram)}
      />
      <Route
        exact
        path="/user/registration/questions"
        component={withAuth(UserRegistrationQuestions)}
      />
      <Route
        exact
        path="/user/registration/premierprogram"
        component={withAuth(premierProgram)}
      />
      <Route
        exact
        path="/user/registration/developmentprogram"
        component={withAuth(DevelopmentProgram)}
      />
      <Route
        exact
        path="/user/registration/checkout"
        component={withAuth(UserRegistrationCheckout)}
      />
      <Route
        exact
        path="/user/registration/congratulations"
        component={withAuth(TeamWithCodeConfirmation)}
      />
      <Route
        exact
        path="/user/registration/campwaiver"
        component={withAuth(CampWaiver)}
      />
      <Route
        exact
        path="/user/parentrequest/:referralId"
        component={ParentRequest}
      />
      <Route exact path="/user/parentrequest" component={ParentRequest} />
      <Route exact path="/user/contactinfo" component={ContactInfo} />
      <Route
        exact
        path="/user/requestconfirmation"
        component={ParentRequestConfirmation}
      />
      <Route
        exact
        path="/user/frontfacingregistration/parentinformation"
        component={ParentInformation}
      />
      <Route
        exact
        path="/user/frontfacingregistration/invitefriends/:teamCode"
        component={FrontFacingInviteFriends}
      />
      <Route
        exact
        path="/user/frontfacingregistration/invitefriends"
        component={FrontFacingInviteFriends}
      />
      <Route
        exact
        path="/user/tournaments/createTeam"
        component={CreateTournamentTeam}
      />
      <Route
        exact
        path="/user/tournaments/invitation/:teamCode"
        component={TournamentInvitation}
      />
      <Route
        exact
        path="/user/tournaments/invitation"
        component={TournamentInvitation}
      />
      <Route
        exact
        path="/user/tournaments/confirmation"
        component={withAuth(TournamentTeamConfirmation)}
      />
      <Route
        exact
        path="/user/tournaments/freeagentcongratulations"
        component={withAuth(TournamentFreeAgentConfirmation)}
      />
      <Route
        exact
        path="/user/teampoolteam/congratulations"
        component={withAuth(TeampoolTeamConfirmation)}
      />
      <Route
        exact
        path="/user/teampool/congratulations"
        component={withAuth(TeampoolConfirmation)}
      />
      <Route
        exact
        path="/user/team/congratulations"
        component={withAuth(TeamWithCodeConfirmation)}
      />
      <Route
        exact
        path="/user/tryouts/congratulations"
        component={withAuth(TeamWithCodeConfirmation)}
      />
      {/* Coach Review */}
      <Route
        exact
        path="/user/coachreview/rating/:reviewId"
        component={CoachReviewRating}
      />
      <Route
        exact
        path="/user/coachreview/rating"
        component={withAuth(CoachReviewRating)}
      />
      <Route
        exact
        path="/user/coachreview/comment"
        component={withAuth(CoachReviewComment)}
      />
      <Route
        exact
        path="/user/coachreview/compliment"
        component={withAuth(CoachReviewCompliment)}
      />
      <Route
        exact
        path="/user/coachreview/confirmation"
        component={withAuth(CoachReviewConfirmation)}
      />
      <Route
        exact
        path="/user/coachreview/gift"
        component={withAuth(CoachReviewGift)}
      />
      <Route
        exact
        path="/user/coachreview/gift/:reviewId"
        component={withAuth(CoachReviewGift)}
      />
      <Route
        exact
        path="/coach/reviewrating"
        component={withAuth(CoachReviewRatingProfile)}
      />
      <Route
        exact
        path="/coach/reviewlist"
        component={withAuth(CoachReviewList)}
      />
      <Route
        exact
        path="/coach/coverageRequest/:requestId"
        component={withAuth(CoachCoverageRequest)}
      />
      {/* User Schedules */}
      <Route
        exact
        path="/user/schedules"
        component={withAuth(UserSchedules, "schedule")}
      />
      {/* Contact Us */}
      <Route
        exact
        path="/user/contactus"
        component={withAuth(ContactUs, "contactus")}
      />
      {/* User Messages */}
      <Route
        exact
        path="/user/messages"
        component={withAuth(UserMessages, "messages")}
      />
      {/* My Account */}
      <Route
        exact
        path="/user/updateprofile"
        component={withAuth(UserUpdateProfile, "profile")}
      />
      <Route exact path="/user/myplayers" component={withAuth(UserMyPlayers)} />
      <Route
        exact
        path="/user/myplayers/:playerId"
        component={withAuth(UserMyPlayers)}
      />
      <Route exact path="/user/addplayer" component={withAuth(UserAddPlayer)} />
      <Route
        exact
        path="/user/editplayer/:id"
        component={withAuth(UserEditPlayer)}
      />
      <Route
        exact
        path="/user/changepassword"
        component={withAuth(UserChangePassword)}
      />
      <Route
        exact
        path="/coach-resources"
        component={withAuth(CoachResources)}
      />
      <Route exact path="/user/payments" component={withAuth(UserPayments)} />
      <Route exact path="/forgotpassword" component={ForgotPassword} />
      <Route exact path="/deleteuser" component={DeleteUser} />
      <Route exact path="/league/:id" component={League} />
      <Route exact path="/archiveleagues" component={LeagueArchive} />
      <Route exact path="/coach/coverage" component={CoachCoverage} />
      {/* 404: Page Not Found */}
      <Route component={Page404} />
    </Switch>
  </App>
);
