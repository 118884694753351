import React, { Component } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import loadable from "@loadable/component";

import "../Register.css";

const Header = loadable(() => import("../Header"));
const Footer = loadable(() => import("../../footer/Footer"));

class SixteenthPage extends Component {
  componentDidMount() {
    document.getElementById("scroll-to-this").scrollIntoView();
    var video = document.getElementById("register-coach-second-video");
    var button = document.getElementById("register-coach-second-video-button");
    var hyperlink = document.getElementById(
      "register-coach-second-video-hyperlink"
    );
    video.addEventListener(
      "ended",
      function () {
        button.disabled = false;
        hyperlink.style.pointerEvents = "auto";
      },
      true
    );
    if (video.paused) video.play();
    else video.pause();
  }

  render() {
    /* Novo dodano moje */
    const backgroundStyle = {
      backgroundImage: "url(/images/sixteenthpagebg.png)",
      backgroundRepeat: "no-repeat",
      backgroundSize: "auto",
      justifyContent: "center",
      alignItems: "center",
      height: "800px",
      display: "flex",
    };

    return (
      <div className="welcome-coach">
        <Header />
        <div style={backgroundStyle} className="big-container-reg">
          <div
            id="scroll-to-this"
            className="register-welcome-container2 register-sixteenth-shadow"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
              <div
                style={{
                  fontSize: "35px",
                  color: "rgb(43, 43, 44)",
                }}
              >
                <b>A brief tutorial on how to navigate your portal. </b>
              </div>
              <div className="register-coach-video-container">
                <video
                  id="register-coach-second-video"
                  style={{ width: "100%", height: "300px" }}
                >
                  <source src="/images/secondpagevideo.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  id="register-coach-second-video-button"
                  disabled
                  className="register-coach-button"
                >
                  <Link
                    id="register-coach-second-video-hyperlink"
                    style={{
                      textDecoration: "none",
                      color: "black",
                      pointerEvents: "none",
                    }}
                    to={`/register/coach/new-seventeenth-page`}
                  >
                    Continue
                  </Link>
                </button>
              </div>
              <div style={{ marginTop: "5px" }}>
                <i>Watch video to continue</i>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default SixteenthPage;
