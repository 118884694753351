import React, { Component } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import loadable from "@loadable/component";

import "../Register.css";

const Header = loadable(() => import("../Header"));
const Footer = loadable(() => import("../../footer/Footer"));

class NinthPage extends Component {
  constructor() {
    super();
    this.state = {
      buttonClicked: false,
      buttonClicked2: false,
      buttonClicked3: false,
      buttonClicked4: false,
    };
  }

  componentDidMount() {
    document.getElementById("scroll-to-this").scrollIntoView();
  }

  render() {
    const backgroundStyle = {
      // backgroundImage: "url(/images/greycoachregimage.png)",
      // backgroundRepeat: "no-repeat",
      // backgroundSize: "auto",
      background: "rgb(235, 235, 235)",
      minHeight: "91%",
      // height: "800px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };

    return (
      <div
        className="welcome-coach"
        style={{ height: "100vh", overflow: "scroll" }}
      >
        <Header />
        <div style={backgroundStyle} className="big-container-reg">
          <div
            id="scroll-to-this"
            className="register-welcome-container nobg-container"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                width: "100%",
              }}
            >
              <div
                style={{
                  color: "#0a85ad",
                  marginBottom: "50px",
                  display: "flex",
                  flexDirection: "column",
                }}
                className="notice-container register-notice-container-shadow-yellow"
              >
                <div style={{ fontSize: "20px", fontWeight: 600 }}>
                  <b>Coaching Professionalism Policy</b>
                </div>
              </div>

              <div
                style={{
                  justifyContent: "center",
                  display:
                    this.state.buttonClicked &&
                    this.state.buttonClicked2 &&
                    this.state.buttonClicked3 &&
                    this.state.buttonClicked4
                      ? "flex"
                      : "none",
                  marginBottom: "20px",
                }}
              >
                <button
                  id="register-coach-button"
                  className="register-coach-button"
                >
                  <Link
                    id="register-coach-hyperlink"
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                    to={`/register/coach/new-tenth-page`}
                  >
                    <b>Continue</b>
                  </Link>
                </button>
              </div>
              <div className="big-ninth-container">
                <div className="leftside-container-special notice-container register-small-notice-container-shadow-lightblue">
                  We pride ourselves on having great coaches. As a new coach, we
                  need you to uphold our standards of integrity, positivity, and
                  professionalism.
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      fontSize: "20px",
                      justifyContent: "space-around",
                    }}
                  >
                    <div className="notice-container register-small-notice-container-shadow-lightblue rule-box">
                      <div>
                        <img src="/images/clockicon.png" alt="clock" />
                      </div>
                      <div>Arrive early</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          position: "absolute",
                          right: "-20px",
                          top: "-20px",
                        }}
                      >
                        {this.state.buttonClicked ? (
                          <img
                            src="/images/greencheckmark.png"
                            alt="green checkmark"
                          />
                        ) : (
                          // <Link
                          //   id="register-coach-hyperlink"
                          //   style={{
                          //     textDecoration: "none",
                          //     color: "black",
                          //     pointerEvents:
                          //       this.state.buttonClicked &&
                          //       this.state.buttonClicked2 &&
                          //       this.state.buttonClicked3 &&
                          //       this.state.buttonClicked4
                          //         ? "auto"
                          //         : "none",
                          //   }}
                          //   to={`/register/coach/new-tenth-page`}
                          // >
                          //   <img
                          //     style={{ width: "100px" }}
                          //     src="/images/greencheckmark.png"
                          //     alt="green checkmark"
                          //   />
                          // </Link>
                          <img
                            style={{ cursor: "pointer" }}
                            src="/images/graycheckmark.png"
                            alt="green checkmark"
                            onClick={() =>
                              this.setState({ buttonClicked: true })
                            }
                          />
                        )}
                      </div>
                    </div>
                    <div className="notice-container register-small-notice-container-shadow-lightblue rule-box">
                      <div>
                        <img src="/images/listiconstuff.png" alt="list" />
                      </div>
                      <div>Plan your practices</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          position: "absolute",
                          right: "-20px",
                          top: "-20px",
                        }}
                      >
                        {this.state.buttonClicked2 ? (
                          <img
                            src="/images/greencheckmark.png"
                            alt="green checkmark"
                          />
                        ) : (
                          // <Link
                          //   id="register-coach-hyperlink"
                          //   style={{
                          //     textDecoration: "none",
                          //     color: "black",
                          //     pointerEvents:
                          //       this.state.buttonClicked &&
                          //       this.state.buttonClicked2 &&
                          //       this.state.buttonClicked3 &&
                          //       this.state.buttonClicked4
                          //         ? "auto"
                          //         : "none",
                          //   }}
                          //   to={`/register/coach/new-tenth-page`}
                          // >
                          //   <img
                          //     src="/images/greencheckmark.png"
                          //     alt="green checkmark"
                          //   />
                          // </Link>
                          <img
                            style={{ cursor: "pointer" }}
                            src="/images/graycheckmark.png"
                            alt="green checkmark"
                            onClick={() =>
                              this.setState({ buttonClicked2: true })
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      fontSize: "25px",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <div className="notice-container register-small-notice-container-shadow-lightblue rule-box">
                      <div>
                        <img src="/images/reefeeree.png" alt="ref" />
                      </div>
                      <div>Respect the Refs!</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          position: "absolute",
                          right: "-20px",
                          top: "-20px",
                        }}
                      >
                        {this.state.buttonClicked3 ? (
                          <img
                            src="/images/greencheckmark.png"
                            alt="green checkmark"
                          />
                        ) : (
                          // <Link
                          //   id="register-coach-hyperlink"
                          //   style={{
                          //     textDecoration: "none",
                          //     color: "black",
                          //     pointerEvents:
                          //       this.state.buttonClicked &&
                          //       this.state.buttonClicked2 &&
                          //       this.state.buttonClicked3 &&
                          //       this.state.buttonClicked4
                          //         ? "auto"
                          //         : "none",
                          //   }}
                          //   to={`/register/coach/new-tenth-page`}
                          // >
                          //   <img
                          //     src="/images/greencheckmark.png"
                          //     alt="green checkmark"
                          //   />
                          // </Link>
                          <img
                            style={{ cursor: "pointer" }}
                            src="/images/graycheckmark.png"
                            alt="green checkmark"
                            onClick={() =>
                              this.setState({ buttonClicked3: true })
                            }
                          />
                        )}
                      </div>
                    </div>
                    <div className="notice-container register-small-notice-container-shadow-lightblue rule-box">
                      <div>
                        <img src="/images/coachsmallicon.png" alt="coach" />
                      </div>
                      <div>Always be positive</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          position: "absolute",
                          right: "-20px",
                          top: "-20px",
                        }}
                      >
                        {this.state.buttonClicked4 ? (
                          <img
                            src="/images/greencheckmark.png"
                            alt="green checkmark"
                          />
                        ) : (
                          // <Link
                          //   id="register-coach-hyperlink"
                          //   style={{
                          //     textDecoration: "none",
                          //     color: "black",
                          //     pointerEvents:
                          //       this.state.buttonClicked &&
                          //       this.state.buttonClicked2 &&
                          //       this.state.buttonClicked3 &&
                          //       this.state.buttonClicked4
                          //         ? "auto"
                          //         : "none",
                          //   }}
                          //   to={`/register/coach/new-tenth-page`}
                          // >
                          //   <img
                          //     src="/images/greencheckmark.png"
                          //     alt="green checkmark"
                          //   />
                          // </Link>
                          <img
                            style={{ cursor: "pointer" }}
                            src="/images/graycheckmark.png"
                            alt="green checkmark"
                            onClick={() =>
                              this.setState({ buttonClicked4: true })
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  id="register-coach-button"
                  className="register-coach-button"
                >
                  <Link
                    id="register-coach-hyperlink"
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                    to={`/register/coach/new-ninth-page`}
                  >
                    <b>Continue</b>
                  </Link>
                </button>
              </div> */}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default NinthPage;
