import React, { Component } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import loadable from "@loadable/component";

import "../Register.css";

const Header = loadable(() => import("../Header"));
const Footer = loadable(() => import("../../footer/Footer"));

class SecondPage extends Component {
  componentDidMount() {
    document.getElementById("scroll-to-this").scrollIntoView();
    var video = document.getElementById("register-coach-second-video");
    var button = document.getElementById("register-coach-second-video-button");
    button.style.backgroundColor = "lightgray";
    var hyperlink = document.getElementById(
      "register-coach-second-video-hyperlink"
    );
    video.addEventListener(
      "ended",
      function () {
        button.disabled = false;
        hyperlink.style.pointerEvents = "auto";
        button.style.backgroundColor = "#f7f76f";
      },
      true
    );
    if (video.paused) video.play();
    else video.pause();
  }

  render() {
    /* Novo dodano moje */
    const backgroundStyle = {
      // backgroundImage: "url(/images/greycoachregimage.png)",
      // backgroundRepeat: "no-repeat",
      // backgroundSize: "auto",
      background: "rgb(235, 235, 235)",
      minHeight: "91%",
      // height: "800px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };

    return (
      <div
        className="welcome-coach"
        style={{ height: "100vh", overflow: "scroll" }}
      >
        <Header />
        <div style={backgroundStyle} className="big-container-reg">
          <div
            id="scroll-to-this"
            className="register-welcome-container register-second-shadow"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
              <div style={{ fontSize: "30px", color: "#0a85ad" }}>
                <b>To start - a welcome message from our President.</b>
              </div>
              <div className="register-coach-video-container">
                <video
                  id="register-coach-second-video"
                  style={{ width: "100%", height: "300px" }}
                  controls
                >
                  <source src="/images/secondpagevideo.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  id="register-coach-second-video-button"
                  disabled
                  className="register-coach-button"
                >
                  <Link
                    id="register-coach-second-video-hyperlink"
                    style={{
                      textDecoration: "none",
                      color: "black",
                      pointerEvents: "none",
                    }}
                    to={`/register/coach/new-third-page`}
                  >
                    Continue
                  </Link>
                </button>
              </div>
              <div style={{ marginTop: "5px" }}>
                <i>Watch video to continue</i>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      /* Novo dodano moje */

      // <div className="welcome-coach">
      //     <Header />
      //     <div className="welcome-main-content"  style={backgroundStyle}>
      //         <Container className="welcome-coach-content">
      //             <Row>
      //                 <Col >
      //                     <h1>Welcome...</h1>
      //                     <h2>Welcome to the PPA Portal! This will be your hub for everything you need as a PPA Coach.</h2>
      //                     <h2>First we need you to complete this registration: </h2>
      //                 </Col>
      //             </Row>

      //             <Row>
      //                 <Col>
      //                     <Button color="success">
      //                         <Link to={`/register/coach/join`}>Join</Link>
      //                     </Button>
      //                 </Col>
      //             </Row>
      //         </Container>
      //     </div>
      //     <Footer/>
      // </div>
    );
  }
}

export default SecondPage;
