import React, { Component } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import loadable from "@loadable/component";

import "../Register.css";

const Header = loadable(() => import("../Header"));
const Footer = loadable(() => import("../../footer/Footer"));

class EleventhPage extends Component {
  constructor() {
    super();
    this.state = { buttonClicked: false };
  }

  componentDidMount() {
    document.getElementById("scroll-to-this").scrollIntoView();
  }

  render() {
    const backgroundStyle = {
      // backgroundImage: "url(/images/greycoachregimage.png)",
      // backgroundRepeat: "no-repeat",
      // backgroundSize: "auto",
      background: "rgb(235, 235, 235)",
      minHeight: "91%",
      // height: "800px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };

    return (
      <div
        className="welcome-coach"
        style={{ overflow: "scroll", height: "100vh" }}
      >
        <Header />
        <div style={backgroundStyle} className="big-container-reg">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                padding: "15px",
                width: "50%",
                border: "5px solid rgb(43, 43, 44)",
                borderRadius: "20px",
                marginBottom: "30px",
                fontSize: "25px",
                fontWeight: 600,
              }}
            >
              Non-Compete Agreement
            </div>
            <div
              id="scroll-to-this"
              className="register-welcome-container part1-policies-container register-part1-policies-container-shadow"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    color: "#0d7ba0",
                    fontSize: "15px",
                    paddingBottom: "50px",
                  }}
                >
                  <div
                    style={{
                      fontWeight: 600,
                      marginBottom: "10px",
                      marginTop: "10px",
                    }}
                  >
                    While coaching with PPA, we will ask you to not provide any
                    type of private coaching services to PPA Players without
                    approval of a Managing Director. This includes:
                  </div>
                  <div>- Training PPA Players privately</div>
                  <div>
                    - Training any teams with PPA players on the team privately
                  </div>
                  <div>
                    - Running your own sports camps or clinics privately
                  </div>
                  <div
                    style={{
                      fontWeight: 600,
                      marginBottom: "10px",
                    }}
                  >
                    While coaching with PPA, you cannot work for a competing
                    youth sports organization without approval of a managing
                    Director. This includes:
                  </div>
                  <div>- Coaching for another club</div>
                  <div>
                    - Any type of work that would conflict with the interests of
                    PPA
                  </div>
                  <div
                    style={{
                      fontWeight: 600,
                      marginBottom: "10px",
                      marginTop: "10px",
                    }}
                  >
                    You cannot represent or solicit materials on behalf of any
                    competitor to PPA players, parents, or staff. This includes:
                  </div>
                  <div>
                    - Wearing clothing for competing organizations at PPA
                    functions
                  </div>
                  <div>
                    - Sending emails or any other communications to PPA Parents,
                    PPA Players, or PPA Staff that does not have relevance to a
                    PPA function
                  </div>
                  <div
                    style={{
                      fontWeight: 600,
                      marginTop: "10px",
                    }}
                  >
                    You cannot recruit PPA Coaches or other PPA staff to join a
                    competing organization.
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {this.state.buttonClicked ? (
                    <img
                      src="/images/greencheckmark.png"
                      alt="green checkmark"
                    />
                  ) : (
                    <img
                      style={{ cursor: "pointer" }}
                      src="/images/graycheckmark.png"
                      alt="green checkmark"
                      onClick={() => this.setState({ buttonClicked: true })}
                    />
                  )}
                  {this.state.buttonClicked ? (
                    <button
                      id="register-coach-button"
                      className="register-coach-button"
                    >
                      <Link
                        id="register-coach-hyperlink"
                        style={{
                          textDecoration: "none",
                          color: "black",
                        }}
                        to={`/register/coach/new-twelfth-page`}
                      >
                        Continue
                      </Link>
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default EleventhPage;
