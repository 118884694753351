import React, { Component } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import loadable from "@loadable/component";

import "../Register.css";

const Header = loadable(() => import("../Header"));
const Footer = loadable(() => import("../../footer/Footer"));

class ThirdPage extends Component {
  componentDidMount() {
    document.getElementById("scroll-to-this").scrollIntoView();
  }

  render() {
    /* Novo dodano moje */
    const backgroundStyle = {
      // backgroundImage: "url(/images/greycoachregimage.png)",
      // backgroundRepeat: "no-repeat",
      // backgroundSize: "auto",
      background: "rgb(235, 235, 235)",
      minHeight: "91%",
      // height: "800px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };

    return (
      <div
        className="welcome-coach"
        style={{ height: "100vh", overflow: "scroll" }}
      >
        <Header />
        <div style={backgroundStyle} className="big-container-reg">
          <div
            id="scroll-to-this"
            className="register-welcome-container nobg-container"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                width: "100%",
              }}
            >
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: 600,
                  color: "#0a85ad",
                  marginBottom: "40px",
                }}
                className="notice-container register-notice-container-shadow"
              >
                <b>What you will gain through this registration process</b>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="register-round-containing-container">
                  <div className="register-round-container register-small-notice-container-shadow">
                    <b>1</b>
                  </div>
                  <div className="register-next-to-round-container register-small-notice-container-shadow notice-container">
                    Introduction to PPA Work Policies
                  </div>
                </div>
                <div className="register-round-containing-container">
                  <div className="register-round-container register-small-notice-container-shadow">
                    <b>2</b>
                  </div>
                  <div className="register-next-to-round-container register-small-notice-container-shadow notice-container">
                    How to use the PPA Portal &amp; App
                  </div>
                </div>
                <div className="register-round-containing-container">
                  <div className="register-round-container register-small-notice-container-shadow">
                    <b>3</b>
                  </div>
                  <div className="register-next-to-round-container register-small-notice-container-shadow notice-container">
                    Account Creation
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  id="register-coach-button"
                  className="register-coach-button"
                >
                  <Link
                    id="register-coach-hyperlink"
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                    to={`/register/coach/new-fourth-page`}
                  >
                    <b>Continue</b>
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      /* Novo dodano moje */

      // <div className="welcome-coach">
      //     <Header />
      //     <div className="welcome-main-content"  style={backgroundStyle}>
      //         <Container className="welcome-coach-content">
      //             <Row>
      //                 <Col >
      //                     <h1>Welcome...</h1>
      //                     <h2>Welcome to the PPA Portal! This will be your hub for everything you need as a PPA Coach.</h2>
      //                     <h2>First we need you to complete this registration: </h2>
      //                 </Col>
      //             </Row>

      //             <Row>
      //                 <Col>
      //                     <Button color="success">
      //                         <Link to={`/register/coach/join`}>Join</Link>
      //                     </Button>
      //                 </Col>
      //             </Row>
      //         </Container>
      //     </div>
      //     <Footer/>
      // </div>
    );
  }
}

export default ThirdPage;
