import React, { Component } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import loadable from "@loadable/component";

import "../Register.css";

const Header = loadable(() => import("../Header"));
const Footer = loadable(() => import("../../footer/Footer"));

class SeventeenthPage extends Component {
  componentDidMount() {
    document.getElementById("scroll-to-this").scrollIntoView();
  }

  render() {
    const backgroundStyle = {
      // backgroundImage: "url(/images/greycoachregimage.png)",
      // backgroundRepeat: "no-repeat",
      // backgroundSize: "auto",
      background: "rgb(235, 235, 235)",
      minHeight: "91%",
      // height: "800px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };

    return (
      <div
        className="welcome-coach"
        style={{ overflow: "scroll", height: "100vh" }}
      >
        <Header />
        <div style={backgroundStyle} className="big-container-reg">
          <div
            id="scroll-to-this"
            className="register-welcome-container part1-policies-container register-notice-container-shadow-yellow-strong"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                width: "100%",
                position: "relative",
              }}
            >
              <div
                className="icon-13-classes"
                style={{ position: "absolute", top: "-110px", left: "-90px" }}
              >
                <img src="/images/pencilicon.png" alt="pencil" />
              </div>
              <div
                style={{
                  fontWeight: 900,
                  color: "darkblue",
                  textShadow: "7px 7px lightgray",
                }}
                className="part-text-container"
              >
                PART 3
              </div>
              <div
                style={{
                  color: "darkblue",
                  fontSize: "50px",
                  fontWeight: 600,
                  paddingBottom: "50px",
                }}
              >
                Account creation
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  id="register-coach-button"
                  className="register-coach-button"
                >
                  <Link
                    id="register-coach-hyperlink"
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                    to={`/register/coach/join`}
                  >
                    Continue
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default SeventeenthPage;
