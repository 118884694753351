import React, { Component } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import loadable from "@loadable/component";

import "../Register.css";

const Header = loadable(() => import("../Header"));
const Footer = loadable(() => import("../../footer/Footer"));

class FourteenthPage extends Component {
  constructor() {
    super();
    this.state = {
      buttonClicked: false,
      buttonClicked2: false,
      buttonClicked3: false,
      buttonClicked4: false,
      buttonClicked5: false,
      buttonClicked6: false,
    };
  }

  componentDidMount() {
    document.getElementById("scroll-to-this").scrollIntoView();
  }

  render() {
    const backgroundStyle = {
      // backgroundImage: "url(/images/greycoachregimage.png)",
      // backgroundRepeat: "no-repeat",
      // backgroundSize: "auto",
      background: "rgb(235, 235, 235)",
      minHeight: "91%",
      // height: "800px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };

    return (
      <div
        className="welcome-coach"
        style={{ overflow: "scroll", height: "100vh" }}
      >
        <Header />
        <div style={backgroundStyle} className="big-container-reg">
          <div
            id="scroll-to-this"
            className="register-welcome-container nobg-container"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                width: "100%",
              }}
            >
              <div
                style={{
                  justifyContent: "center",
                  display:
                    this.state.buttonClicked &&
                    this.state.buttonClicked2 &&
                    this.state.buttonClicked3 &&
                    this.state.buttonClicked4 &&
                    this.state.buttonClicked5 &&
                    this.state.buttonClicked6
                      ? "flex"
                      : "none",
                  marginBottom: "20px",
                }}
              >
                <button
                  id="register-coach-button"
                  className="register-coach-button"
                >
                  <Link
                    id="register-coach-hyperlink"
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                    to={`/register/coach/new-fifteenth-page`}
                  >
                    <b>Continue</b>
                  </Link>
                </button>
              </div>
              <div className="big-ninth-container">
                <div className="leftside-container-special2 notice-container register-small-notice-container-shadow-lightblue">
                  The PPA Portal, which you are about to have access to, is your
                  central hub for coaching with PPA. In this portal, you will be
                  able to access your schedule, message with your teams, check
                  your rosters, buy coaching gear and more. Its critical that
                  you understand how to use your portal account.
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      fontSize: "25px",
                      justifyContent: "space-around",
                    }}
                  >
                    <div className="notice-container register-small-notice-container-shadow-lightblue rule-box">
                      <div>
                        <img src="/images/messagingicon.png" alt="mail" />
                      </div>
                      <div>Team messaging</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          position: "absolute",
                          right: "-20px",
                          top: "-20px",
                        }}
                      >
                        {this.state.buttonClicked ? (
                          <img
                            src="/images/greencheckmark.png"
                            alt="green checkmark"
                          />
                        ) : (
                          // <Link
                          //   id="register-coach-hyperlink"
                          //   style={{
                          //     textDecoration: "none",
                          //     color: "black",
                          //     pointerEvents:
                          //       this.state.buttonClicked &&
                          //       this.state.buttonClicked2 &&
                          //       this.state.buttonClicked3 &&
                          //       this.state.buttonClicked4 &&
                          //       this.state.buttonClicked5 &&
                          //       this.state.buttonClicked6
                          //         ? "auto"
                          //         : "none",
                          //   }}
                          //   to={`/register/coach/new-fifteenth-page`}
                          // >
                          //   <img
                          //     style={{ width: "100px" }}
                          //     src="/images/greencheckmark.png"
                          //     alt="green checkmark"
                          //   />
                          // </Link>
                          <img
                            style={{ cursor: "pointer" }}
                            src="/images/graycheckmark.png"
                            alt="green checkmark"
                            onClick={() =>
                              this.setState({ buttonClicked: true })
                            }
                          />
                        )}
                      </div>
                    </div>

                    <div className="notice-container register-small-notice-container-shadow-lightblue rule-box">
                      <div>
                        <img src="/images/moneyicon.png" alt="money" />
                      </div>
                      <div>Payments</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          position: "absolute",
                          right: "-20px",
                          top: "-20px",
                        }}
                      >
                        {this.state.buttonClicked2 ? (
                          <img
                            src="/images/greencheckmark.png"
                            alt="green checkmark"
                          />
                        ) : (
                          // <Link
                          //   id="register-coach-hyperlink"
                          //   style={{
                          //     textDecoration: "none",
                          //     color: "black",
                          //     pointerEvents:
                          //       this.state.buttonClicked &&
                          //       this.state.buttonClicked2 &&
                          //       this.state.buttonClicked3 &&
                          //       this.state.buttonClicked4 &&
                          //       this.state.buttonClicked5 &&
                          //       this.state.buttonClicked6
                          //         ? "auto"
                          //         : "none",
                          //   }}
                          //   to={`/register/coach/new-fifteenth-page`}
                          // >
                          //   <img
                          //     src="/images/greencheckmark.png"
                          //     alt="green checkmark"
                          //   />
                          // </Link>
                          <img
                            style={{ cursor: "pointer" }}
                            src="/images/graycheckmark.png"
                            alt="green checkmark"
                            onClick={() =>
                              this.setState({ buttonClicked2: true })
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      fontSize: "25px",
                      justifyContent: "space-around",
                    }}
                  >
                    <div className="notice-container register-small-notice-container-shadow-lightblue rule-box">
                      <div>
                        <img src="/images/scheduleicon.png" alt="clock" />
                      </div>
                      <div>Schedules</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          position: "absolute",
                          right: "-20px",
                          top: "-20px",
                        }}
                      >
                        {this.state.buttonClicked5 ? (
                          <img
                            src="/images/greencheckmark.png"
                            alt="green checkmark"
                          />
                        ) : (
                          // <Link
                          //   id="register-coach-hyperlink"
                          //   style={{
                          //     textDecoration: "none",
                          //     color: "black",
                          //     pointerEvents:
                          //       this.state.buttonClicked &&
                          //       this.state.buttonClicked2 &&
                          //       this.state.buttonClicked3 &&
                          //       this.state.buttonClicked4 &&
                          //       this.state.buttonClicked5 &&
                          //       this.state.buttonClicked6
                          //         ? "auto"
                          //         : "none",
                          //   }}
                          //   to={`/register/coach/new-fifteenth-page`}
                          // >
                          //   <img
                          //     style={{ width: "100px" }}
                          //     src="/images/greencheckmark.png"
                          //     alt="green checkmark"
                          //   />
                          // </Link>
                          <img
                            style={{ cursor: "pointer" }}
                            src="/images/graycheckmark.png"
                            alt="green checkmark"
                            onClick={() =>
                              this.setState({ buttonClicked5: true })
                            }
                          />
                        )}
                      </div>
                    </div>

                    <div className="notice-container register-small-notice-container-shadow-lightblue rule-box">
                      <div>
                        <img src="/images/calendaricon.png" alt="calendar" />
                      </div>
                      <div>Coverage</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          position: "absolute",
                          right: "-20px",
                          top: "-20px",
                        }}
                      >
                        {this.state.buttonClicked6 ? (
                          <img
                            src="/images/greencheckmark.png"
                            alt="green checkmark"
                          />
                        ) : (
                          // <Link
                          //   id="register-coach-hyperlink"
                          //   style={{
                          //     textDecoration: "none",
                          //     color: "black",
                          //     pointerEvents:
                          //       this.state.buttonClicked &&
                          //       this.state.buttonClicked2 &&
                          //       this.state.buttonClicked3 &&
                          //       this.state.buttonClicked4 &&
                          //       this.state.buttonClicked5 &&
                          //       this.state.buttonClicked6
                          //         ? "auto"
                          //         : "none",
                          //   }}
                          //   to={`/register/coach/new-fifteenth-page`}
                          // >
                          //   <img
                          //     style={{ width: "100px" }}
                          //     src="/images/greencheckmark.png"
                          //     alt="green checkmark"
                          //   />
                          // </Link>
                          <img
                            style={{ cursor: "pointer" }}
                            src="/images/graycheckmark.png"
                            alt="green checkmark"
                            onClick={() =>
                              this.setState({ buttonClicked6: true })
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      fontSize: "25px",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <div className="notice-container register-small-notice-container-shadow-lightblue rule-box">
                      <div>
                        <img src="/images/rostericon.png" alt="rostr" />
                      </div>
                      <div>Rosters</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          position: "absolute",
                          right: "-20px",
                          top: "-20px",
                        }}
                      >
                        {this.state.buttonClicked3 ? (
                          <img
                            src="/images/greencheckmark.png"
                            alt="green checkmark"
                          />
                        ) : (
                          // <Link
                          //   id="register-coach-hyperlink"
                          //   style={{
                          //     textDecoration: "none",
                          //     color: "black",
                          //     pointerEvents:
                          //       this.state.buttonClicked &&
                          //       this.state.buttonClicked2 &&
                          //       this.state.buttonClicked3 &&
                          //       this.state.buttonClicked4 &&
                          //       this.state.buttonClicked5 &&
                          //       this.state.buttonClicked6
                          //         ? "auto"
                          //         : "none",
                          //   }}
                          //   to={`/register/coach/new-fifteenth-page`}
                          // >
                          //   <img
                          //     style={{ width: "100px" }}
                          //     src="/images/greencheckmark.png"
                          //     alt="green checkmark"
                          //   />
                          // </Link>
                          <img
                            style={{ cursor: "pointer" }}
                            src="/images/graycheckmark.png"
                            alt="green checkmark"
                            onClick={() =>
                              this.setState({ buttonClicked3: true })
                            }
                          />
                        )}
                      </div>
                    </div>
                    <div className="notice-container register-small-notice-container-shadow-lightblue rule-box">
                      <div>
                        <img src="/images/feedbackicon.png" alt="feedback" />
                      </div>
                      <div>Feedback</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          position: "absolute",
                          right: "-20px",
                          top: "-20px",
                        }}
                      >
                        {this.state.buttonClicked4 ? (
                          <img
                            src="/images/greencheckmark.png"
                            alt="green checkmark"
                          />
                        ) : (
                          // <Link
                          //   id="register-coach-hyperlink"
                          //   style={{
                          //     textDecoration: "none",
                          //     color: "black",
                          //     pointerEvents:
                          //       this.state.buttonClicked &&
                          //       this.state.buttonClicked2 &&
                          //       this.state.buttonClicked3 &&
                          //       this.state.buttonClicked4 &&
                          //       this.state.buttonClicked5 &&
                          //       this.state.buttonClicked6
                          //         ? "auto"
                          //         : "none",
                          //   }}
                          //   to={`/register/coach/new-fifteenth-page`}
                          // >
                          //   <img
                          //     style={{ width: "100px" }}
                          //     src="/images/greencheckmark.png"
                          //     alt="green checkmark"
                          //   />
                          // </Link>
                          <img
                            style={{ cursor: "pointer" }}
                            src="/images/graycheckmark.png"
                            alt="green checkmark"
                            onClick={() =>
                              this.setState({ buttonClicked4: true })
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default FourteenthPage;
