import React, { Component } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import loadable from "@loadable/component";

import "../Register.css";

const Header = loadable(() => import("../Header"));
const Footer = loadable(() => import("../../footer/Footer"));

class SixthPage extends Component {
  constructor() {
    super();
    this.state = { buttonClicked: false };
  }

  componentDidMount() {
    document.getElementById("scroll-to-this").scrollIntoView();
  }

  render() {
    /* Novo dodano moje */
    const backgroundStyle = {
      // backgroundImage: "url(/images/greycoachregimage.png)",
      // backgroundRepeat: "no-repeat",
      // backgroundSize: "auto",
      background: "rgb(235, 235, 235)",
      minHeight: "91%",
      // height: "800px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };

    return (
      <div
        className="welcome-coach"
        style={{ height: "100vh", overflow: "scroll" }}
      >
        <Header />
        <div
          id="main-containing-body"
          style={backgroundStyle}
          className="big-container-reg"
        >
          <div
            id="scroll-to-this"
            className="register-welcome-container nobg-container"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                width: "100%",
              }}
            >
              <div style={{ position: "relative" }}>
                <div
                  style={{
                    fontSize: "25px",
                    color: "#0a85ad",
                    marginBottom: "50px",
                  }}
                  className="notice-container register-notice-container-yellow"
                >
                  <b>Dress code: While coaching always wear PPA gear</b>
                </div>
                <div
                  id="sixth-page-continue"
                  style={{
                    display: "none",
                    justifyContent: "center",
                  }}
                >
                  <button
                    id="register-coach-second-video-button"
                    disabled
                    className="register-coach-button"
                  >
                    <Link
                      id="register-coach-second-video-hyperlink"
                      style={{
                        textDecoration: "none",
                        color: "black",
                      }}
                      to={`/register/coach/new-seventh-page`}
                    >
                      Continue
                    </Link>
                  </button>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "absolute",
                    right: "0px",
                    top: "50px",
                  }}
                >
                  {this.state.buttonClicked ? (
                    <img
                      src="/images/greencheckmark.png"
                      alt="green checkmark"
                    />
                  ) : (
                    // <Link
                    //   id="register-coach-hyperlink"
                    //   style={{
                    //     textDecoration: "none",
                    //     color: "black",
                    //   }}
                    //   to={`/register/coach/new-seventh-page`}
                    // >
                    //   <img
                    //     style={{ width: "100px" }}
                    //     src="/images/greencheckmark.png"
                    //     alt="green checkmark"
                    //   />
                    // </Link>
                    <img
                      style={{ cursor: "pointer" }}
                      src="/images/graycheckmark.png"
                      alt="green checkmark"
                      onClick={() => {
                        this.setState({ buttonClicked: true });
                        document.getElementById(
                          "sixth-page-continue"
                        ).style.display = "flex";
                      }}
                    />
                  )}
                </div>
              </div>
              <div
                className="flex-container-positioning"
                style={{ marginTop: "30px" }}
              >
                <div className="gear-box register-notice-container-yellow-small">
                  <img
                    src="/images/clothing1.png"
                    alt="clothing 1"
                    style={{ width: "90px" }}
                  />
                </div>
                <div className="gear-box register-notice-container-yellow-small">
                  <img
                    src="/images/clothing2.png"
                    alt="clothing 2"
                    style={{ width: "90px" }}
                  />
                </div>
                <div className="gear-box register-notice-container-yellow-small">
                  <img
                    src="/images/clothing3.png"
                    alt="clothing 3"
                    style={{ width: "90px" }}
                  />
                </div>
              </div>
              <div
                className="flex-container-positioning"
                style={{ marginTop: "30px" }}
              >
                <div className="gear-box register-notice-container-yellow-small">
                  <img
                    src="/images/clothing4.png"
                    alt="clothing 4"
                    style={{ width: "70px" }}
                  />
                </div>
                <div className="gear-box register-notice-container-yellow-small">
                  <img
                    src="/images/clothing5.png"
                    alt="clothing 5"
                    style={{ width: "90px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default SixthPage;
